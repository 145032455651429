
































































import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { appointment } from "@/shared/dtos/appointment";
import appointmentModule from "@/store/modules/appointment-module";
import resourceModule from "@/store/modules/resource-module";
import { DxDateBox } from "devextreme-vue";
import { resource } from "@/shared/dtos/resource";
import { paciente } from "@/shared/dtos/paciente-dto";
import { UtilsDate } from "@/utils/utils-date";
import { UtilsNotify } from "@/utils/utils-notify";
import { ssmMessageService } from "@/shared/services/message-service";
import pacienteModule from "@/store/modules/paciente-module";
@Component({
  components: {
    DxDateBox,
    PacientesSeleccion: () =>
      import("@/views/pacientes/pacientes-seleccion.vue")
  }
})
export default class AddCita extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: Boolean;
  @PropSync("defaultpaci", { type: Number }) synceddefaultpaci!: number;
  @Prop() usefecha!: boolean;
  @Prop() fecha!: Date;
  public Appoiment: appointment = new appointment();
  public RecusoSeleccionado: resource = new resource();
  public pacienteSeleccionado: paciente = new paciente();
  public created() {
    this.RefreshDataComponent();
    resourceModule.getresources();
  }

  public get getColorToolbar() {
    try {
      if (
        this.RecusoSeleccionado.Color === undefined ||
        this.RecusoSeleccionado.Color === null
      ) {
        return "orange";
      }
    } catch (error) {
      return "orange";
    }

    return this.RecusoSeleccionado.Color;
  }
  public closeDialog() {
    this.syncedDialog = false;
    this.Appoiment = new appointment();
    this.Appoiment = new appointment();
    this.RecusoSeleccionado = new resource();
    this.pacienteSeleccionado = new paciente();
  }
  public get resources() {
    this.RecusoSeleccionado = resourceModule.resources[0];
    return resourceModule.resources;
  }

  @Watch("pacienteSeleccionado")
  public pacienteSeleccionadoChanged() {
    if (
      this.pacienteSeleccionado.nombre == undefined ||
      this.pacienteSeleccionado.apellidos == undefined
    ) {
      return "";
    }

    this.Appoiment.Paciente = this.pacienteSeleccionado;
    this.Appoiment.Subject =
      this.pacienteSeleccionado.nombre +
      " " +
      this.pacienteSeleccionado.apellidos;
  }
  @Watch("syncedDialog")
  public OpenDialog() {
    if (this.syncedDialog) {
      this.RefreshDataComponent();
      //---- para seleccionar un paciente por defecto.
      if (this.synceddefaultpaci !== undefined) {
        if (this.synceddefaultpaci !== null) {
          if (this.synceddefaultpaci > 0) {
            pacienteModule
              .searchpaciente(this.synceddefaultpaci)
              .then(
                () => (this.pacienteSeleccionado = pacienteModule.paciente)
              );
          }
        }
      }
      //----
    }
  }
  public seleccione(seleccion: string) {
    ssmMessageService.alert(
      seleccion,
      "Para poder guardar la cita correctamente",
      ssmMessageService.TypeError
    );
  }
  public AceptarCita() {
    if (
      this.Appoiment.StartDate === undefined ||
      this.Appoiment.EndDate === undefined
    ) {
      this.seleccione("Seleccione un rango de fechas valido");
      return;
    }
    try {
      if (
        this.Appoiment.StartDate.toString() === "" ||
        this.Appoiment.EndDate.toString() === ""
      ) {
        this.seleccione("Seleccione un rango de fechas valido");
        return;
      }
    } catch (error) {
      this.seleccione("Seleccione un rango de fechas valido");
    }
    if (this.pacienteSeleccionado.numero == undefined) {
      this.seleccione("Seleccione un paciente");
      return;
    }
    this.Appoiment.CustomField1 = this.pacienteSeleccionado.numero;
    this.Appoiment.ResourceID = this.RecusoSeleccionado.ResourceID;
    appointmentModule
      .guardarappointment(this.Appoiment)
      .then(() => this.CitaGuardadaCorrectamente())
      .catch(() => this.seleccione("No se ha podido guardar"));
  }
  public CitaGuardadaCorrectamente() {
    try {
      appointmentModule.Addappointment(this.Appoiment);
      UtilsNotify.NotificacionSuccess(
        "Cita guardada",
        "Su cita se ha guardado correctamente"
      );
      this.closeDialog();
    } catch (error) {}
  }
  public RefreshDataComponent() {
    /*Creo por defecto un recuso pendiente para que nada de error en el template*/
    this.RecusoSeleccionado.ResourceID = 0;
    this.RecusoSeleccionado.ResourceName = "Cita Pendiente";
    this.RecusoSeleccionado.Color = "orange";
    this.RecusoSeleccionado.Icono = "lens";
    this.Appoiment.Paciente = new paciente();
    if (this.usefecha) {
      this.Appoiment.StartDate = this.fecha;
      this.Appoiment.EndDate = UtilsDate.AddMinutos(this.fecha, 15);
    } else {
      this.Appoiment.StartDate = new Date();
      this.Appoiment.EndDate = UtilsDate.AddMinutos(new Date(), 15);
    }
  }
}
